import Vue from 'vue'
import VueRouter from 'vue-router'
import WCasino from '../views/WCasino.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: WCasino,
    meta: { title: 'casino', keepAlive: false, isHide: false }
  },
  {
    path: '/casino/group',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "casinoGroup" */ '../views/CasinoGroup.vue'),
    meta: { title: 'group', keepAlive: false, isHide: false }
  },
  {
    path: '/casino/game',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "casinoGame" */ '../views/CasinoGame.vue'),
    meta: { title: 'game', keepAlive: false, isHide: false }
  },
  {
    path: '/casino',
    name: 'casino',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "casino" */ '../views/WCasino.vue'),
    meta: { title: 'casino', keepAlive: false, isHide: false }
  },
  {
    path: '/pages/wallet/index',
    name: 'wallet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "wallet" */ '../views/WWallet.vue'),
    meta: { title: 'wallet', keepAlive: false, isHide: false }
  },
  {
    path: '/transactions',
    name: 'transactions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "transactions" */ '../views/WTransactions.vue'
      ),
    meta: { title: 'transactions', keepAlive: false, isHide: false }
  },
  {
    path: '/convidar',
    name: 'convidar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "convidar" */ '../views/WConvidar.vue'),
    meta: { title: 'convidar', keepAlive: false, isHide: false }
  },
  {
    path: '/colocacao',
    name: 'colocacao',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "colocacao" */ '../views/WColocacao.vue'),
    meta: { title: 'colocacao', keepAlive: false, isHide: false }
  },
  {
    path: '/favorites',
    name: 'favorites',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "favorites" */ '../views/WFavorites.vue'),
    meta: { title: 'favorites', keepAlive: false, isHide: false }
  },
  {
    path: '/recent',
    name: 'recent',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "recent" */ '../views/WRecent.vue'),
    meta: { title: 'recent', keepAlive: false, isHide: false }
  },
  {
    path: '/promotions/detail',
    name: 'promotionsDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "promotions" */ '../views/PromotionsDetail.vue'),
    meta: { title: 'promotionsDetail', keepAlive: false, isHide: false }
  },
  {
    path: '/promotions',
    name: 'promotions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "promotions" */ '../views/WPromotions.vue'),
    meta: { title: 'promotions', keepAlive: false, isHide: false }
  },
  {
    path: '/pay',
    name: 'pay',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "promotions" */ '../views/WPay'),
    meta: { title: 'pay', keepAlive: false, isHide: false }
  },
  {
    path: '/play',
    name: 'play',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "promotions" */ '../views/WPlay'),
    meta: { title: 'play', keepAlive: false, isHide: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    from.meta.scrollTop = window.scrollY;
    return { x: 0, y: to.meta.scrollTop || 0 };
  }
})

const { back, replace, push } = router;

router.back = function() {
  this.isBack = true;
  back.call(router);
};

router.push = function (location, onComplete, onAbort) {
  // 判断如果没有指定回调函数, 通过call调用源函数并使用catch来处理错误
  if (onComplete===undefined && onAbort===undefined) {
    return push.call(this, location, onComplete, onAbort).catch(() => {})
  } else { // 如果有指定任意回调函数, 通过call调用源push函数处理
    push.call(this, location, onComplete, onAbort)
  }
};

router.replace = function(...args) {
  this.isReplace = true;
  replace.call(router, ...args);
};

router.beforeEach((to, from, next) => {
  document.title = 'bbqls.com-' + to.meta.title
  next()
})

export default router
